import React from 'react';
import Nav from '../Components/nav';
import Home from '../Components/home';
import Plans from '../Components/plans';
import Testimonials from '../Components/testimonials';
import AboutUs from '../Components/about';
import ContactPage from '../Components/Contact';
import Services from '../Components/Services';
import Footer from '../Components/Footer';
import Product from '../Components/Products';

const Main = () => {
  return (
    <div className="app-container">
      <Nav />
      <div id="home"><Home /></div>
      <div id="services"><Services /></div>
      <div id="plans"><Plans /></div>
      <div id="products"><Product /></div>
      <div id="about"><AboutUs /></div>
      <div id="testimonials"><Testimonials /></div>
      <div id="contact"><ContactPage /></div>
      <Footer />
    </div>
  );
};

export default Main;