import React, { useState } from 'react';
import styled from 'styled-components';
import { Check, Star, Zap, Award } from 'lucide-react';

const PlansContainer = styled.div`
  padding: 80px 0;
  background: #f8f9fa;
`;

const ViewMore = styled.button`
  background: none;
  border: none;
  color: #00b894;
  font-size: 0.9rem;
  cursor: pointer;
  padding: 5px 0;
  margin: 10px 0;
  text-decoration: underline;
  
  &:hover {
    color: #00a884;
  }
`;

const Section = styled.section`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  @media (max-width: 768px) {
    padding: 0 15px;
  }
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  color: #2d3436;
  text-align: center;
  margin-bottom: 50px;
  font-weight: 700;
  
  &:after {
    content: '';
    display: block;
    width: 80px;
    height: 4px;
    background: #00b894;
    margin: 15px auto;
  }
`;

const Subtitle = styled.p`
  text-align: center;
  color: #636e72;
  font-size: 1.1rem;
  margin-bottom: 60px;
`;

const PlansGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  padding: 1rem;
`;

const PlanCard = styled.div`
  background: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.05);
  transition: transform 0.3s ease;
  position: relative;
  border: ${props => props.isPopular ? '2px solid #00b894' : '1px solid #eee'};
  
  &:hover {
    transform: translateY(-5px);
  }
`;

const PopularBadge = styled.span`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: #00b894;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 500;
`;

const PlanName = styled.h3`
  color: #2d3436;
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const PlanPrice = styled.div`
  font-size: 2.5rem;
  color: #2d3436;
  font-weight: bold;
  margin-bottom: 1rem;
`;

const PriceDiscount = styled.div`
  color: #e17055;
  font-size: 1rem;
  text-decoration: line-through;
  margin-bottom: 2rem;
`;

const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 2rem 0;
`;

const Feature = styled.li`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 1rem;
  color: #636e72;
  font-size: 1rem;
`;

const StyledCheck = styled(Check)`
  color: #00b894;
`;

const Button = styled.button`
  width: 100%;
  padding: 1rem;
  border-radius: 10px;
  border: ${props => props.isPopular ? 'none' : '2px solid #00b894'};
  background: ${props => props.isPopular ? '#00b894' : 'white'};
  color: ${props => props.isPopular ? 'white' : '#00b894'};
  font-size: 1.1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0,184,148,0.2);
  }
`;

const Plans = () => {
  const [showAll, setShowAll] = useState(false);
  const [hoveredPlan, setHoveredPlan] = useState(null);

  const plans = [
    {
      name: 'Starter',
      icon: <Star size={24} color="#00b894" />,
      price: '₹2,999',
      originalPrice: '₹9,999',
      features: [
        'Professional Website Design',
        'Mobile-First Responsive Design',
        'Contact Form Integration',
        '3 Custom Pages',
        '1 Month Free Maintenance',
        'WhatsApp Integration',
      ],
    },
    {
      name: 'Growth',
      icon: <Zap size={24} color="#00b894" />,
      price: '₹11,999',
      originalPrice: '₹24,999',
      features: [
        'Everything in Starter',
        'Blog/News Section',
        '7 Custom Pages',
        'Advanced SEO Package',
        '3 Months Free Maintenance',
        'Priority Support',
      ],
      popular: true,
    },
    {
      name: 'Premium',
      icon: <Award size={24} color="#00b894" />,
      price: '₹29,999',
      originalPrice: '₹59,999',
      features: [
        'Everything in Growth',
        'E-commerce Integration',
        'Payment Gateway Setup',
        'Inventory Management',
        'Order Tracking System',
        'Customer Portal',
        '6 Months Free Maintenance',
        'Dedicated Support Team',
      ],
    },
  ];

  return (
    <PlansContainer>
      <Section>
        <SectionTitle>Invest in Your Digital Success</SectionTitle>
        <Subtitle>Limited Time Offer - Save up to ₹30,000 on Premium Plans!</Subtitle>
        
        <PlansGrid>
          {plans.map((plan) => (
            <PlanCard
              key={plan.name}
              isPopular={plan.popular}
              onMouseEnter={() => setHoveredPlan(plan.name)}
              onMouseLeave={() => setHoveredPlan(null)}
            >
              {plan.popular && (
                <PopularBadge>Best Value</PopularBadge>
              )}
              
              <PlanName>
                {plan.icon}
                {plan.name}
              </PlanName>
              <PlanPrice>{plan.price}</PlanPrice>
              <PriceDiscount>{plan.originalPrice}</PriceDiscount>
              
              <FeatureList>
                {plan.features.slice(0, 4).map((feature, index) => (
                  <Feature key={index}>
                    <StyledCheck size={20} />
                    {feature}
                  </Feature>
                ))}
                {plan.features.length > 4 && (
                  <ViewMore onClick={() => setShowAll(prev => !prev)}>
                    {showAll ? 'View Less' : `View ${plan.features.length - 4} More`}
                  </ViewMore>
                )}
                {showAll && plan.features.slice(4).map((feature, index) => (
                  <Feature key={index + 4}>
                    <StyledCheck size={20} />
                    {feature}
                  </Feature>
                ))}
              </FeatureList>
              
              <Button isPopular={plan.popular}>
                Start Your Journey
              </Button>
            </PlanCard>
          ))}
        </PlansGrid>
      </Section>
    </PlansContainer>
  );
};

export default Plans;