import React from 'react';
import styled from 'styled-components';
import { Linkedin, Github, Twitter } from 'lucide-react';
import murugesh from '../assets/murugesh.jpg';
import dharanee from '../assets/dharanee.jpg';
import suresh from '../assets/suresh.jpg';
import aalan from '../assets/Aalan.jpg';

const AboutContainer = styled.div`
  padding: 80px 0;
  background: #f8f9fa;
`;

const Section = styled.section`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  @media (max-width: 768px) {
    padding: 0 15px;
  }
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  color: #2d3436;
  text-align: center;
  margin-bottom: 50px;
  font-weight: 700;
  
  &:after {
    content: '';
    display: block;
    width: 80px;
    height: 4px;
    background: #00b894;
    margin: 15px auto;
  }
`;

const AboutContent = styled.div`
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  color: #636e72;
  font-size: 1.1rem;
  line-height: 1.8;
`;

const MissionVisionContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  margin: 80px 0;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Card = styled.div`
  background: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.05);
  transition: transform 0.3s ease;
  
  &:hover {
    transform: translateY(-5px);
  }
`;

const CardTitle = styled.h3`
  color: #2d3436;
  font-size: 1.8rem;
  margin-bottom: 20px;
`;

const CardContent = styled.p`
  color: #636e72;
  line-height: 1.6;
`;

const TeamContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-top: 60px;
`;

const TeamMember = styled.div`
  background: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0,0,0,0.05);
  transition: transform 0.3s ease;
  
  &:hover {
    transform: translateY(-5px);
  }
`;

const MemberImage = styled.img`
  width: 100%;
  height: 300px;
  object-fit: cover;
`;

const MemberInfo = styled.div`
  padding: 20px;
`;

const MemberName = styled.h4`
  color: #2d3436;
  font-size: 1.5rem;
  margin-bottom: 10px;
`;

const MemberRole = styled.p`
  color: #00b894;
  font-weight: 600;
  margin-bottom: 15px;
`;

const MemberBio = styled.p`
  color: #636e72;
  margin-bottom: 20px;
`;

const SocialLinks = styled.div`
  display: flex;
  gap: 15px;
  justify-content: center;
`;

const SocialLink = styled.a`
  color: #636e72;
  transition: color 0.3s ease;
  
  &:hover {
    color: #00b894;
  }
`;

const AboutUs = () => {
  const founders = [
    {
      name: "Murugesh",
      role: "CEO & Full Stack Developer",
      image: murugesh,
      bio: "10+ years of experience in web development and team leadership.",
      linkedin: "https://www.linkedin.com/in/murugesh-s/",
      github: "https://github.com/Murugesh1804",
      twitter: "#"
    },
    {
      name: "Dharanee",
      role: "CTO & Backend Architect",
      image: dharanee,
      bio: "Cloud infrastructure expert with a passion for scalable solutions.",
      linkedin: "https://www.linkedin.com/in/dharaneedharan1825/",
      github: "https://github.com/dharaneechinnu",
      twitter: "#"
    },
    {
      name: "Suresh",
      role: "Creative Director",
      image: suresh,
      bio: "Award-winning designer specializing in user experience.",
      linkedin: "https://www.linkedin.com/in/suresh-p-727b68246/",
      github: "https://github.com/Suresh-272",
      twitter: "#"
    },
    {
      name: "Aalan Sason Singarayan",
      role: "CEO",
      image: aalan,
      bio: "Award-winning designer specializing in user experience.",
      linkedin: "https://www.linkedin.com/in/aalan294",
      github: "https://github.com/aalan294",
      twitter: "#"
    }
  ];

  return (
    <AboutContainer>
      <Section>
        <SectionTitle>About Us</SectionTitle>
        <AboutContent>
          We are a passionate team of developers, designers, and digital innovators dedicated to 
          transforming ideas into powerful digital solutions. With our combined expertise and 
          innovative approach, we help businesses thrive in the digital age.
        </AboutContent>

        <MissionVisionContainer>
          <Card>
            <CardTitle>Our Mission</CardTitle>
            <CardContent>
              To empower businesses with cutting-edge digital solutions that drive growth, 
              enhance user experience, and create lasting impact in the digital landscape.
            </CardContent>
          </Card>
          <Card>
            <CardTitle>Our Vision</CardTitle>
            <CardContent>
              To be the leading force in digital innovation, setting new standards in web 
              development and creating transformative digital experiences that shape the future.
            </CardContent>
          </Card>
        </MissionVisionContainer>

        <SectionTitle>Meet Our Founders</SectionTitle>
        <TeamContainer>
          {founders.map((founder, index) => (
            <TeamMember key={index}>
              <MemberImage src={founder.image} alt={founder.name} />
              <MemberInfo>
                <MemberName>{founder.name}</MemberName>
                <MemberRole>{founder.role}</MemberRole>
                <MemberBio>{founder.bio}</MemberBio>
                <SocialLinks>
                  <SocialLink href={founder.linkedin} target="_blank">
                    <Linkedin size={20} />
                  </SocialLink>
                  <SocialLink href={founder.github} target="_blank">
                    <Github size={20} />
                  </SocialLink>
                  <SocialLink href={founder.twitter} target="_blank">
                    <Twitter size={20} />
                  </SocialLink>
                </SocialLinks>
              </MemberInfo>
            </TeamMember>
          ))}
        </TeamContainer>
      </Section>
    </AboutContainer>
  );
};

export default AboutUs;
