import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { Code, Layout, Server, ArrowRight, Star, Coffee } from 'lucide-react';

//commit
const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const slideInLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const typewriter = keyframes`
  from {
    width: 0;
  }
  to {
    width: var(--width);
  }
`;

const deleteText = keyframes`
  from {
    width: var(--width);
  }
  to {
    width: 0;
  }
`;

const cursorBlink = keyframes`
  from, to {
    border-color: transparent;
  }
  50% {
    border-color: #00b894;
  }
`;

const scaleIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const HomeContainer = styled.div`
  min-height: 100vh;
  background: #ffffff;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='%2300b894' fill-opacity='0.05' fill-rule='evenodd'/%3E%3C/svg%3E");
    opacity: 0.5;
    animation: ${fadeInUp} 1.5s ease-out;
  }
`;

const MainSection = styled.section`
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  align-items: center;
  min-height: 85vh;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    text-align: center;
  }
`;

const ContentSection = styled.div`
  position: relative;
  z-index: 1;
  animation: ${slideInLeft} 1.2s ease-out;
`;

const Title = styled.h1`
  font-size: 3.8rem;
  font-weight: 800;
  color: #2d3436;
  margin-bottom: 25px;
  line-height: 1.2;
  opacity: 0;
  animation: ${fadeInUp} 1s ease-out forwards;
  animation-delay: 0.5s;
  
  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

const GreenSpan = styled.span`
  color: #00b894;
`;

const TypingText = styled.span`
  display: inline-block;
  overflow: hidden;
  border-right: 4px solid #00b894;
  white-space: nowrap;
  color: #00b894;
  margin-bottom: -12px;
  width: 0;
  --width: ${props => `${props.wordLength}ch`};
  animation: 
    ${typewriter} 2s steps(20, end) forwards,
    ${deleteText} 1.5s steps(20, end) 2.5s forwards,
    ${cursorBlink} 0.8s step-end infinite;
`;

const Description = styled.p`
  font-size: 1.1rem;
  color: #636e72;
  line-height: 1.7;
  margin-bottom: 35px;
  position: relative;
  opacity: 0;
  animation: ${fadeInUp} 1s ease-out forwards;
  animation-delay: 0.8s;
  
  &::after {
    content: '';
    position: absolute;
    bottom: -15px;
    left: 0;
    width: 80px;
    height: 4px;
    background: #00b894;
    border-radius: 2px;
    
    @media (max-width: 1024px) {
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;

const InteractiveCard = styled.div`
  background: #ffffff;
  border-radius: 15px;
  padding: 35px;
  box-shadow: 0 10px 25px rgba(0,0,0,0.08);
  transition: transform 0.4s ease, box-shadow 0.4s ease;
  border: 1px solid rgba(0,0,0,0.08);
  opacity: 0;
  animation: ${scaleIn} 1s ease-out forwards;
  animation-delay: 1s;

  &:hover {
    transform: translateY(-8px) scale(1.02);
    box-shadow: 0 20px 40px rgba(0,0,0,0.12);
  }
`;

const PriceTag = styled.div`
  font-size: 2.5rem;
  font-weight: 800;
  color: #00b894;
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transform: scale(0.9);
  animation: ${scaleIn} 0.6s ease-out forwards;
  animation-delay: 1.2s;

  span {
    font-size: 1.2rem;
    color: #636e72;
  }
`;

const FeatureList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  margin: 25px 0;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Feature = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #2d3436;
  font-size: 1rem;
  opacity: 0;
  animation: ${fadeInUp} 0.5s ease-out forwards;
  animation-delay: ${props => props.index * 0.2 + 1.4}s;

  svg {
    color: #00b894;
    transform: scale(0);
    animation: ${scaleIn} 0.4s ease-out forwards;
    animation-delay: ${props => props.index * 0.2 + 1.6}s;
  }
`;

const CTAButton = styled.button`
  background: #00b894;
  color: white;
  border: none;
  padding: 15px 35px;
  font-size: 1.1rem;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.4s ease;
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 35px auto;
  opacity: 0;
  animation: ${fadeInUp} 0.8s ease-out forwards;
  animation-delay: 2s;

  &:hover {
    background: #00a884;
    transform: translateY(-3px) scale(1.05);
    box-shadow: 0 10px 20px rgba(0,184,148,0.3);
  }
`;

const Home = () => {
  const [currentWord, setCurrentWord] = useState(0);
  const words = ["Affordable", "Professional", "Fast"];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWord((prev) => (prev + 1) % words.length);
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  return (
    <HomeContainer>
      <MainSection>
        <ContentSection>
          <Title>
            Transform Your Business Online{' '}
            <br />
            <TypingText key={currentWord} wordLength={words[currentWord].length}>
              {words[currentWord]}
            </TypingText>
          </Title>
          <Description>
            Looking for a budget-friendly website without compromising on quality? 
            We deliver stunning, professional websites 
            that won't break the bank.<br/>Get your dream website today!
          </Description>
        </ContentSection>

        <InteractiveCard>
          <Star size={35} color="#00b894" />
          <PriceTag>
            ₹2,999 <span>/starting</span>
          </PriceTag>
          <FeatureList>
            <Feature index={0}>
              <Code size={18} /> Custom Development
            </Feature>
            <Feature index={1}>
              <Layout size={18} /> Mobile Responsive
            </Feature>
            <Feature index={2}>
              <Server size={18} /> Fast Delivery
            </Feature>
            <Feature index={3}>
              <Coffee size={18} /> Free Consultation
            </Feature>
          </FeatureList>
          <CTAButton>
            Start Your Project <ArrowRight size={18} />
          </CTAButton>
        </InteractiveCard>
      </MainSection>
    </HomeContainer>
  );
};

export default Home;