import React from 'react';
import { Facebook, Twitter, Instagram, Linkedin, Mail, Phone, Clock, MapPin, Globe, Code, Award, ArrowUpRight } from 'lucide-react';

const Footer = () => {
  const styles = {
    footer: {
      backgroundColor: '#2d3436',
      color: 'white',
      paddingTop: '4rem',
      paddingBottom: '2rem'
    },
    container: {
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '0 1rem'
    },
    grid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
      gap: '3rem',
      marginBottom: '3rem'
    },
    heading: {
      fontSize: '1.25rem',
      fontWeight: 'bold',
      color: '#00b894',
      marginBottom: '1.5rem'
    },
    text: {
      color: '#cbd5e0',
      fontSize: '0.875rem',
      lineHeight: '1.5'
    },
    socialLinks: {
      display: 'flex',
      gap: '1rem',
      marginTop: '1.5rem'
    },
    socialIcon: {
      color: '#a0aec0',
      transition: 'color 0.3s ease',
      cursor: 'pointer'
    },
    serviceItem: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      color: '#cbd5e0',
      cursor: 'pointer',
      transition: 'color 0.3s ease',
      marginBottom: '0.75rem'
    },
    contactItem: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.75rem',
      color: '#cbd5e0',
      marginBottom: '1rem'
    },
    link: {
      color: '#cbd5e0',
      textDecoration: 'none',
      transition: 'color 0.3s ease'
    },
    bottomSection: {
      borderTop: '1px solid #4a5568',
      paddingTop: '2rem',
      marginTop: '2rem',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',
      gap: '1rem'
    },
    bottomLinks: {
      display: 'flex',
      gap: '1.5rem'
    }
  };

  return (
    <footer style={styles.footer}>
      <div style={styles.container}>
        <div style={styles.grid}>
          {/* Company Info Section */}
          <div>
            <h3 style={styles.heading}>Web2Smith</h3>
            <p style={styles.text}>
              Transforming ideas into powerful digital solutions. We specialize in custom web development, 
              responsive design, and cutting-edge web applications.
            </p>
            <div style={styles.socialLinks}>
              {[Facebook, Twitter, Instagram, Linkedin].map((Icon, index) => (
                <a key={index} href="#" style={{...styles.socialIcon, ':hover': {color: '#00b894'}}}>
                  <Icon size={20} />
                </a>
              ))}
            </div>
          </div>

          {/* Services Section */}
          <div>
            <h3 style={styles.heading}>Our Services</h3>
            {[
              { icon: <Globe size={16} />, text: "Website Development" },
              { icon: <Code size={16} />, text: "Custom Web Applications" },
              { icon: <Award size={16} />, text: "UI/UX Design" },
              { icon: <ArrowUpRight size={16} />, text: "API Integration" }
            ].map((service, index) => (
              <div key={index} style={styles.serviceItem}>
                {service.icon}
                <span>{service.text}</span>
              </div>
            ))}
          </div>

          {/* Contact Info Section */}
          <div>
            <h3 style={styles.heading}>Contact Us</h3>
            <div>
              {[
                { icon: <Mail size={16} />, text: "web2smith.site@gmail.com", href: "mailto:web2smith.site@gmail.com" },
                { icon: <Phone size={16} />, text: "+91-7397475123", href: "tel:+917397475123" },
                { icon: <Clock size={16} />, text: "Mon - Fri: 9:00 AM - 6:00 PM" },
                { icon: <MapPin size={16} />, text: "Chennai, India" }
              ].map((item, index) => (
                <div key={index} style={styles.contactItem}>
                  {item.icon}
                  {item.href ? (
                    <a href={item.href} style={styles.link}>{item.text}</a>
                  ) : (
                    <span>{item.text}</span>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Bottom Section */}
        <div style={styles.bottomSection}>
          <div style={styles.text}>
            &copy; {new Date().getFullYear()} Web2Smith. All rights reserved.
          </div>
          <div style={styles.bottomLinks}>
            {['Privacy Policy', 'Terms of Service', 'Cookie Policy'].map((text, index) => (
              <a key={index} href="#" style={styles.link}>
                {text}
              </a>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;