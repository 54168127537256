import React from 'react';
import { HashRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import Main from './Pages/Main';

function App() {
  return (
    <Router>
      <Routes>


        <Route path="/" element={<Main />} />



      </Routes>
    </Router>
  );
}

export default App;