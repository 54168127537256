import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

const Nav = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [activeLink, setActiveLink] = useState('home');
  const sidebarRef = useRef();

  const navigationLinks = [
    { id: 'services', label: 'Services' },
    { id: 'plans', label: 'Plans' },
    { id: 'about', label: 'About' },
    { id: 'contact', label: 'Contact' }
  ];

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setScrolled(scrollPosition > 50);

      // Update active section based on scroll position
      const sections = navigationLinks.map(link => ({
        id: link.id,
        offset: document.getElementById(link.id)?.offsetTop - 100
      })).filter(section => section.offset !== undefined);

      const currentSection = sections.reduce((acc, section) => {
        return scrollPosition >= section.offset ? section.id : acc;
      }, sections[0]?.id);

      setActiveLink(currentSection);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleNavClick = (e, sectionId) => {
    e.preventDefault();
    const element = document.getElementById(sectionId);
    if (element) {
      const offset = element.offsetTop - 80;
      window.scrollTo({
        top: offset,
        behavior: 'smooth'
      });
      setActiveLink(sectionId);
      setIsOpen(false);
    }
  };

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <Container scrolled={scrolled}>
      <MainContent>
        <NavSection>
          <LogoContainer>
            <Logo href="#home" onClick={(e) => handleNavClick(e, 'home')}>
              <LogoText>Web<LogoSpan>2</LogoSpan>Smith</LogoText>
            </Logo>
          </LogoContainer>
          <NavLinks>
            {navigationLinks.map(link => (
              <NavItem key={link.id} active={activeLink === link.id}>
                <NavLink 
                  href={`#${link.id}`}
                  onClick={(e) => handleNavClick(e, link.id)}
                >
                  {link.label}
                </NavLink>
                <NavLinkUnderline active={activeLink === link.id} />
              </NavItem>
            ))}
          </NavLinks>
        </NavSection>
        <HamburgerButton onClick={() => setIsOpen(!isOpen)} isOpen={isOpen}>
          <HamburgerLine />
          <HamburgerLine />
          <HamburgerLine />
        </HamburgerButton>
      </MainContent>

      <Sidebar ref={sidebarRef} isOpen={isOpen}>
        <SidebarContent>
          {navigationLinks.map(link => (
            <SidebarLink
              key={link.id}
              href={`#${link.id}`}
              onClick={(e) => handleNavClick(e, link.id)}
              active={activeLink === link.id}
            >
              {link.label}
            </SidebarLink>
          ))}
        </SidebarContent>
      </Sidebar>
      {isOpen && <Backdrop onClick={() => setIsOpen(false)} />}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 80px;
  background: ${({ scrolled }) => (scrolled ? '#f8f9fa' : 'rgba(248, 249, 250, 0.9)')};
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: ${({ scrolled }) => (scrolled ? '0 10px 20px rgba(0,0,0,0.05)' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  transition: all 0.3s ease;
`;

const MainContent = styled.main`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  
  @media (max-width: 768px) {
    padding: 0 15px;
  }
`;

const NavSection = styled.nav`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LogoContainer = styled.div`
  position: relative;
  z-index: 2;
`;

const Logo = styled.a`
  text-decoration: none;
  cursor: pointer;
`;

const LogoText = styled.h1`
  font-size: 2rem;
  color: #2d3436;
  margin: 0;
  font-weight: 800;
`;

const LogoSpan = styled.span`
  color: #00b894;
`;

const NavLinks = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const NavItem = styled.div`
  position: relative;
  height: 40px;
  display: flex;
  align-items: center;
`;

const NavLinkUnderline = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: #00b894;
  transform: scaleX(${props => props.active ? 1 : 0});
  transition: transform 0.3s ease;
  transform-origin: ${props => props.active ? 'left' : 'right'};
`;

const NavLink = styled.a`
  color: ${props => props.active ? '#00b894' : '#636e72'};
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: 500;
  transition: color 0.3s ease;
  
  &:hover {
    color: #00b894;
    
    & + ${NavLinkUnderline} {
      transform: scaleX(1);
      transform-origin: left;
    }
  }
`;

const HamburgerButton = styled.button`
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  @media screen and (max-width: 768px) {
    display: flex;
  }
`;

const HamburgerLine = styled.span`
  width: 2rem;
  height: 0.25rem;
  background: #2d3436;
  border-radius: 10px;
  transition: all 0.3s linear;
  position: relative;
  transform-origin: 1px;
`;

const Sidebar = styled.div`
  position: fixed;
  top: 80px;
  right: 0;
  width: 300px;
  height: calc(100vh - 80px);
  background: #f8f9fa;
  transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(100%)')};
  transition: transform 0.3s ease-in-out;
  box-shadow: -10px 0 20px rgba(0,0,0,0.05);
  z-index: 1000;
`;

const SidebarContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  gap: 2rem;
`;

const SidebarLink = styled.a`
  color: ${props => props.active ? '#00b894' : '#636e72'};
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 500;
  transition: color 0.3s ease;
  
  &:hover {
    color: #00b894;
  }
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 999;
`;

export default Nav;