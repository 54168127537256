import React, { useState } from 'react';
import styled from 'styled-components';
import { Mail, Phone, MapPin, User } from 'lucide-react';
import murugesh from '../assets/murugesh.jpg';
import dharanee from '../assets/dharanee.jpg';
import suresh from '../assets/suresh.jpg';
import aalan from '../assets/Aalan.jpg';


const ContactContainer = styled.div`
  padding: 80px 0 0;
  background: #f8f9fa;
`;

const Section = styled.section`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  @media (max-width: 768px) {
    padding: 0 15px;
  }
`;

const FormSection = styled.div`
  background: white;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.05);
  overflow: hidden;
  padding: 3rem;
  margin-bottom: 80px;
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  color: #2d3436;
  text-align: center;
  margin-bottom: 50px;
  font-weight: 700;
  
  &:after {
    content: '';
    display: block;
    width: 80px;
    height: 4px;
    background: #00b894;
    margin: 15px auto;
  }
`;

const Subtitle = styled.p`
  text-align: center;
  color: #636e72;
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 2rem;
`;

const ContactInfo = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2.5rem;
`;

const MemberContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  text-align: center;
  cursor: pointer;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  padding: 1rem;
  background: #ffffff;
  transition: transform 0.2s ease, box-shadow 0.2s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
`;

const MemberImage = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
`;

const MemberName = styled.h4`
  font-size: 1rem;
  font-weight: 600;
  color: #2d3436;
  margin-top:10px;

`;

const MemberRole = styled.p`
  font-size: 0.9rem;
  color: #636e72;
  margin-top:-10px;
`;

const ContactPage = () => {
  const teamMembers = [
    {
      id: 1,
      name: 'Murugesh S',
      role: 'Founder & CEO',
      image: murugesh,
      whatsapp: '9345544982',
    },
    {
      id: 2,
      name: 'Dharaneedharan',
      role: 'Founder',
      image: dharanee,
      whatsapp: '7397475123',
    },
    {
      id: 3,
      name: 'Suresh',
      role: 'Founder',
      image: suresh,
      whatsapp: '6374900655',
    },
    {
      id: 4,
      name: 'Aalan Sason Singarayan',
      role: 'CEO',
      image:aalan,
      whatsapp: '8524889202',
    },
  ];

  const handleWhatsappRedirect = (number) => {
    window.open(`https://wa.me/91${number}`, '_blank');
  };

  return (
    <ContactContainer>
      <Section>
        <FormSection>
          <SectionTitle>Get In Touch</SectionTitle>
          <Subtitle>Contact our team directly through WhatsApp!</Subtitle>

          <ContactInfo>
            {teamMembers.map((member) => (
              <MemberContainer
                key={member.id}
                onClick={() => handleWhatsappRedirect(member.whatsapp)}
              >
                <MemberImage src={member.image} alt={member.name} />
                <MemberName>{member.name}</MemberName>
                <MemberRole>{member.role}</MemberRole>
              </MemberContainer>
            ))}
          </ContactInfo>
        </FormSection>
      </Section>
    </ContactContainer>
  );
};

export default ContactPage;
