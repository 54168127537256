import React from 'react';
import { Award, Download, Upload, Users, CheckCircle, ArrowRight } from 'lucide-react';

const Product = () => {
  const styles = {
    container: {
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '4rem 1rem'
    },
    hero: {
      background: 'linear-gradient(135deg, #2d3436 0%, #1a1e1f 100%)',
      color: 'white',
      padding: '4rem 2rem',
      borderRadius: '1rem',
      marginBottom: '4rem',
      textAlign: 'center'
    },
    heading: {
      fontSize: '2.5rem',
      marginBottom: '1rem',
      color: '#00b894'
    },
    subheading: {
      fontSize: '1.2rem',
      color: '#cbd5e0',
      maxWidth: '800px',
      margin: '0 auto 2rem'
    },
    button: {
      backgroundColor: '#00b894',
      color: 'white',
      padding: '1rem 2rem',
      borderRadius: '0.5rem',
      border: 'none',
      cursor: 'pointer',
      display: 'inline-flex',
      alignItems: 'center',
      gap: '0.5rem',
      fontSize: '1.1rem',
      transition: 'transform 0.2s',
      ':hover': {
        transform: 'translateY(-2px)'
      }
    },
    featuresGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
      gap: '2rem',
      marginBottom: '4rem'
    },
    featureCard: {
      padding: '2rem',
      borderRadius: '0.5rem',
      backgroundColor: '#f8f9fa',
      border: '1px solid #e9ecef'
    },
    featureTitle: {
      fontSize: '1.25rem',
      marginBottom: '1rem',
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      color: '#2d3436'
    },
    featureText: {
      color: '#6c757d',
      lineHeight: '1.6'
    },
    demoSection: {
      backgroundColor: '#f8f9fa',
      padding: '3rem',
      borderRadius: '1rem',
      textAlign: 'center'
    },
    demoImage: {
      maxWidth: '100%',
      height: 'auto',
      borderRadius: '0.5rem',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
    }
  };
  const handleNavigation = (url) => {
    window.location.href = url; // This will navigate to the provided URL
  };
  return (
    <div style={styles.container}>
      {/* Hero Section */}
      <div style={styles.hero}>
        <h1 style={styles.heading}>Bulk Certificate Generator</h1>
        <p style={styles.subheading}>
          Generate professional certificates for your events, workshops, and hackathons in seconds. 
          Upload your data, choose a template, and get all certificates with just one click.
        </p>
        <button onClick={() => handleNavigation('https://rapid-print.onrender.com/')} style={styles.button}>
          Try Generator <ArrowRight size={20} />
        </button>
      </div>

      {/* Features Section */}
      <div style={styles.featuresGrid}>
        <div style={styles.featureCard}>
          <h3 style={styles.featureTitle}>
            <Upload size={24} />
            Bulk Upload
          </h3>
          <p style={styles.featureText}>
            Upload CSV/Excel files with participant details. Support for multiple data formats and custom fields.
          </p>
        </div>

        <div style={styles.featureCard}>
          <h3 style={styles.featureTitle}>
            <Award size={24} />
            Custom Templates
          </h3>
          <p style={styles.featureText}>
            Choose from professional templates or upload your own design. Customize fonts, colors, and layouts.
          </p>
        </div>

        <div style={styles.featureCard}>
          <h3 style={styles.featureTitle}>
            <Download size={24} />
            Instant Download
          </h3>
          <p style={styles.featureText}>
            Generate and download all certificates in PDF format instantly. Bulk ZIP download available.
          </p>
        </div>
      </div>

      {/* Demo Section */}
      {/* <div style={styles.demoSection}>
        <h2 style={{...styles.heading, marginBottom: '2rem'}}>How It Works</h2>
        <img 
          src={prod} 
          alt="Certificate Generator Demo" 
          style={styles.demoImage}
        />
        <div style={{marginTop: '2rem'}}>
          <button style={styles.button}>
            Generate Certificates Now <ArrowRight size={20} />
          </button>
        </div>
      </div> */}
    </div>
  );
};

export default Product;