import React from 'react';
import styled from 'styled-components';
import { Code, Server, Globe, Layout, Palette, CloudLightning, Target, Wrench, Database } from 'lucide-react';

const ServicesContainer = styled.div`
  padding: 80px 0;
  background: #f8f9fa;
`;

const Section = styled.section`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  @media (max-width: 768px) {
    padding: 0 15px;
  }
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  color: #2d3436;
  text-align: center;
  margin-bottom: 50px;
  font-weight: 700;
  
  &:after {
    content: '';
    display: block;
    width: 80px;
    height: 4px;
    background: #00b894;
    margin: 15px auto;
  }
`;

const ServicesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-top: 60px;
`;

const ServiceCard = styled.div`
  background: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.05);
  transition: transform 0.3s ease;
  text-align: center;
  
  &:hover {
    transform: translateY(-5px);
  }
`;

const ServiceIcon = styled.div`
  color: #00b894;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  
  svg {
    width: 40px;
    height: 40px;
  }
`;

const ServiceTitle = styled.h3`
  color: #2d3436;
  font-size: 1.8rem;
  margin-bottom: 20px;
`;

const ServiceDescription = styled.p`
  color: #636e72;
  line-height: 1.6;
  margin-bottom: 20px;
`;

const ServiceDetails = styled.div`
  background: rgba(0,184,148,0.05);
  padding: 15px;
  border-radius: 10px;
  margin-top: 20px;
  
  div {
    color: #636e72;
    margin: 8px 0;
  }
`;

const PriceTag = styled.div`
  background: #00b894;
  color: white;
  padding: 10px 20px;
  border-radius: 50px;
  display: inline-block;
  margin-top: 15px;
  font-weight: 600;
`;

const Services = () => {
  const servicesList = [
    {
      icon: <Code />,
      title: "Website Development",
      description: "Custom-crafted websites that blend stunning design with powerful functionality.",
      details: [
        "Responsive Design",
        "SEO Optimized",
        "Custom CMS Integration"
      ],
      price: "Starting at ₹10,000"
    },
    {
      icon: <Globe />,
      title: "Web Hosting & Deployment",
      description: "Secure, lightning-fast hosting solutions with 99.9% uptime guarantee.",
      details: [
        "Cloud Hosting",
        "SSL Certification", 
        "Daily Backups"
      ],
      price: "From ₹4,000/month"
    },
    {
      icon: <Layout />,
      title: "UI/UX Design",
      description: "User-centric design that enhances user experience and drives engagement.",
      details: [
        "Wireframing",
        "Prototype Design",
        "User Research"
      ],
      price: "Starting at ₹10,000"
    },
    {
      icon: <Wrench />,
      title: "Freelance Support",
      description: "Flexible technical support and maintenance for your digital projects.",
      details: [
        "On-demand Assistance",
        "Bug Fixes & Updates",
        "Technical Consulting"
      ],
      price: "₹2,000/hour"
    },
    {
      icon: <Database />,
      title: "Backend Support",
      description: "Robust and scalable backend solutions to power your applications.",
      details: [
        "API Development",
        "Database Design",
        "Server Management"
      ],
      price: "From ₹75,000/month"
    }
  ];

  return (
    <ServicesContainer>
      <Section>
        <SectionTitle>Our Services</SectionTitle>
        <ServicesGrid>
          {servicesList.map((service, index) => (
            <ServiceCard key={index}>
              <ServiceIcon>{service.icon}</ServiceIcon>
              <ServiceTitle>{service.title}</ServiceTitle>
              <ServiceDescription>{service.description}</ServiceDescription>
              <ServiceDetails>
                {service.details.map((detail, idx) => (
                  <div key={idx}>✓ {detail}</div>
                ))}
              </ServiceDetails>
              <PriceTag>{service.price}</PriceTag>
            </ServiceCard>
          ))}
        </ServicesGrid>
      </Section>
    </ServicesContainer>
  );
};

export default Services;