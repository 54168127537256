import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ChevronLeft, ChevronRight, Star } from 'lucide-react';

const TestimonialsContainer = styled.div`
  padding: 80px 0;
  background: #f8f9fa;
`;

const Section = styled.section`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  @media (max-width: 768px) {
    padding: 0 15px;
  }
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  color: #2d3436;
  text-align: center;
  margin-bottom: 50px;
  font-weight: 700;
  
  &:after {
    content: '';
    display: block;
    width: 80px;
    height: 4px;
    background: #00b894;
    margin: 15px auto;
  }
`;

const TestimonialWrapper = styled.div`
  position: relative;
  padding: 2rem;
`;

const TestimonialSlide = styled.div`
  background: white;
  border-radius: 10px;
  padding: 40px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.05);
  transition: transform 0.3s ease;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  
  &:hover {
    transform: translateY(-5px);
  }
`;

const TestimonialContent = styled.p`
  color: #636e72;
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 30px;
`;

const AuthorInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
`;

const AuthorImage = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
`;

const AuthorDetails = styled.div`
  text-align: left;
`;

const AuthorName = styled.div`
  color: #2d3436;
  font-size: 1.2rem;
  font-weight: 600;
`;

const AuthorRole = styled.div`
  color: #00b894;
  font-weight: 600;
`;

const Rating = styled.div`
  display: flex;
  gap: 5px;
  justify-content: center;
  margin-top: 20px;
  color: #FFD700;
`;

const NavigationButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: white;
  border: none;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 10px 20px rgba(0,0,0,0.05);
  transition: all 0.3s ease;
  color: #2d3436;

  &:hover {
    background: #f0f7ff;
    transform: translateY(-50%) scale(1.1);
  }

  ${props => props.position === 'left' ? 'left: 0;' : 'right: 0;'}
`;

const DotsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 30px;
`;

const Dot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${props => props.active ? '#00b894' : '#CBD5E0'};
  cursor: pointer;
  transition: all 0.3s ease;
  transform: ${props => props.active ? 'scale(1.5)' : 'scale(1)'};
`;

const Testimonials = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const testimonials = [
    {
      name: "Sarah Johnson",
      role: "CEO, TechStart",
      image: "/api/placeholder/80/80",
      content: "Working with this team was an absolute pleasure. They transformed our vision into reality with exceptional attention to detail.",
      rating: 5
    },
    {
      name: "Michael Chen",
      role: "Product Manager, InnovateCo",
      image: "/api/placeholder/80/80",
      content: "The level of expertise and professionalism exceeded our expectations. Our new website has significantly improved our online presence.",
      rating: 5
    },
    {
      name: "Emma Davis",
      role: "Founder, CreativeHub",
      image: "/api/placeholder/80/80",
      content: "Incredible work! They not only delivered a beautiful website but also provided valuable insights throughout the development process.",
      rating: 5
    }
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((current) => 
        current === testimonials.length - 1 ? 0 : current + 1
      );
    }, 5000);

    return () => clearInterval(interval);
  }, [testimonials.length]);

  return (
    <TestimonialsContainer>
      <Section>
        <SectionTitle>What Our Clients Say</SectionTitle>

        <TestimonialWrapper>
          <TestimonialSlide>
            <TestimonialContent>
              {testimonials[activeIndex].content}
            </TestimonialContent>
            
            <AuthorInfo>
              <AuthorImage
                src={testimonials[activeIndex].image}
                alt={testimonials[activeIndex].name}
              />
              <AuthorDetails>
                <AuthorName>{testimonials[activeIndex].name}</AuthorName>
                <AuthorRole>{testimonials[activeIndex].role}</AuthorRole>
              </AuthorDetails>
            </AuthorInfo>

            <Rating>
              {[...Array(testimonials[activeIndex].rating)].map((_, i) => (
                <Star key={i} size={20} fill="#FFD700" />
              ))}
            </Rating>
          </TestimonialSlide>

          <NavigationButton 
            position="left"
            onClick={() => setActiveIndex(current => 
              current === 0 ? testimonials.length - 1 : current - 1
            )}
          >
            <ChevronLeft size={24} />
          </NavigationButton>

          <NavigationButton
            position="right" 
            onClick={() => setActiveIndex(current => 
              current === testimonials.length - 1 ? 0 : current + 1
            )}
          >
            <ChevronRight size={24} />
          </NavigationButton>
        </TestimonialWrapper>

        <DotsContainer>
          {testimonials.map((_, index) => (
            <Dot
              key={index}
              active={activeIndex === index}
              onClick={() => setActiveIndex(index)}
            />
          ))}
        </DotsContainer>
      </Section>
    </TestimonialsContainer>
  );
};

export default Testimonials;